import React, { useState, useRef, useContext, useEffect } from 'react';
import { Scoped, k } from 'kremling';
import { Stack, Paper, IconButton, Typography, Checkbox, Badge, Button as ButtonMui, Menu, MenuItem, Dialog, DialogContent, DialogActions, Button, Modal, Box, DialogTitle } from '@mui/material';
import { TransactionFilledIcon, ReviewFilledIcon, ShoppingCartIcon, SurveysIcon, Calendar30DaysIcon, CustomersFilledIcon, VehicleIcon, ReviewIcon, TransactionIcon, CustomerIcon } from 'components/mui';
import BugReportIcon from '@mui/icons-material/BugReport';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import Tooltip from 'rc-tooltip';
import { RemoveIcon, AddIcon, PanCursorIcon, PublishIcon, TextFieldsIcon, MouseIcon, UndoIcon, WarningIcon, ArrowCircleRightIcon } from 'components/mui';
import { revertJourney, updateNode, patchRevision, getJourneyRevisions, getSegmentNodes } from 'shared/common.api';
import { MODES, mapNode } from './node/node-utils';
import { Toolbar } from './toolbar/toolbar.component';
import { NodeModal, DynamicModalDraft } from './node/node-modal';
import { DateTime } from 'luxon';
import { toasterService } from 'components/toaster/toaster-service';
import { JourneyApp } from './journey-app';
import { RepublishModal } from './republish-modal';
import { DuplicateSegmentModal } from './duplicate-segment-modal';
import moment from 'moment-timezone';
import { ModalContext } from 'context/modal-context';
import { UserStateContext } from 'context/user-state-context';
import { history } from '../../root.component';
import { SaveNodeModal } from './save-node-modal.component';
import { cloneDeep } from 'lodash';
import { TestJourneyModal } from './test-journey-modal.component';
import { SelectTileModal } from './select-tile-modal.component';
const Mousetrap = require('mousetrap');
const LS_JOURNEY_SHOW_GRID = 'journeyShowGrid';
const LS_JOURNEY_SNAP_TO_GRID = 'journeySnapToGrid';
const volume = scale => {
  switch (scale) {
    case 1:
      return 1;
    case 0.9:
      return 1.111;
    case 0.8:
      return 1.25;
    case 0.7:
      return 1.428;
    case 0.6:
      return 1.665;
    case 0.5:
      return 2;
  }
};
export const DraftCanvas = ({
  revision,
  allRevisions,
  setRevision,
  setAllRevisions,
  match,
  setIsLoading,
  nodes
}) => {
  const canvasRef = useRef(null);
  const appRef = useRef(null);
  const {
    confirm,
    show,
    alert
  } = useContext(ModalContext);
  const {
    asCompany,
    flags
  } = useContext(UserStateContext);
  const [showGrid, setShowGrid] = useState(() => localStorage.getItem(LS_JOURNEY_SHOW_GRID) === 'true');
  const [snapToGrid, setSnapToGrid] = useState(() => localStorage.getItem(LS_JOURNEY_SNAP_TO_GRID) === 'true');
  const [zoom, setZoom] = useState(1);
  const [mode, setMode] = useState(MODES.CANVAS);
  const [invalidNodes, setInvalidNodes] = useState([]);
  const [invalidNodeEl, setInvalidNodeEl] = useState();
  const [ignoreWarning, setIgnoreWarning] = useState({});
  const [paramNodes, setParamNodes] = useState({});
  const [sessionTypeChangeWarning, setSessionTypeChangeWarning] = useState(false);
  const [nodeInUse, setNodeInUse] = useState(null);
  const [suppressWarning, setSuppressWarning] = useState(false);
  const sessionDataTiles = ['trigger_transaction', 'trigger_review', 'trigger_abandoned_cart', 'trigger_survey_completed', 'trigger_schedule', 'trigger_time_schedule'];

  // Extra nodes are nodes that we created this session.  We don't want to
  // trigger a full render when these nodes change
  const [extraNodes, setExtraNodes] = useState([]);

  // Initialize app
  useEffect(() => {
    appRef.current = new JourneyApp(canvasRef.current, false, on);
    Mousetrap.bind('a', () => setMode(mode => mode === MODES.EDIT_PARAM ? MODES.EDIT_PARAM : MODES.CANVAS));
    Mousetrap.bind('s', () => setMode(mode => mode === MODES.EDIT_PARAM ? MODES.EDIT_PARAM : MODES.PAN));
    Mousetrap.bind('d', () => setMode(mode => mode === MODES.EDIT_PARAM ? MODES.EDIT_PARAM : MODES.TEXT));
    return () => {
      appRef.current.destroy();
      Mousetrap.unbind('a');
      Mousetrap.unbind('s');
      Mousetrap.unbind('d');
    };
  }, []);
  useEffect(() => {
    appRef.current.setNodeLibrary(nodes);
  }, [nodes]);

  // Sync revision
  useEffect(() => {
    if (revision) {
      appRef.current.setRevision(revision);
    }
  }, [revision]);

  // Sync zoom level
  useEffect(() => {
    appRef.current.setZoom(zoom);
  }, [zoom]);

  // Sync mode
  useEffect(() => {
    appRef.current.setMode(mode);
  }, [mode]);

  // Sync snap to grid to app and storage
  useEffect(() => {
    appRef.current.setSnapToGrid(snapToGrid);
    localStorage.setItem(LS_JOURNEY_SNAP_TO_GRID, `${snapToGrid}`);
  }, [snapToGrid]);

  // Sync snap to grid to app and storage
  useEffect(() => {
    appRef.current.setShowGrid(showGrid);
    localStorage.setItem(LS_JOURNEY_SHOW_GRID, `${showGrid}`);
  }, [showGrid]);
  const checkLinks = nodeFrom => {
    const eventCounts = {};
    const isDuplicate = appRef.current.links.some(link => {
      if (link.nodeFrom.id !== nodeFrom.id) return false;
      const key = `${link.onEvent}`;
      if (!eventCounts[key]) {
        eventCounts[key] = 0;
      }
      eventCounts[key]++;
      return eventCounts[key] > 1;
    });
    if (isDuplicate) {
      alert({
        message: 'You have used the same path twice out of this tile. If this was intentional, you can ignore this warning.',
        title: 'Duplicate Link Detected'
      }).catch(() => {});
    }
  };
  const on = (event, obj, ...args) => {
    if (event === 'SELECT_TILES') {
      setMode(MODES.NODE_PARAM);
      appRef.current.tileParm = obj;
    } else if (event === 'NODES_LOADED') {
      // Tiles are loaded in the Journey App class but we need them in the draft canvas so we can
      // let the journey parameter builder know which nodes already have parameters.
      const newParamNodes = {};
      revision?.meta?.params?.forEach(param => {
        newParamNodes[param.key] = [];
        obj.forEach(node => {
          if (node.parameters) {
            Object.keys(node.parameters).some(pkey => {
              if (node.parameters[pkey] === `##${param.key}##`) {
                newParamNodes[param.key].push({
                  id: node.id,
                  name: node.name
                });
                return true;
              }
              return false;
            });
          }
        });
      });
      setParamNodes(newParamNodes);
    } else if (event === 'EDIT_PARAM') {
      const [lastMode, linkEdit] = args; // dodo figre me wout
      if (!!obj && lastMode === MODES.NODE_PARAM) {
        show(SelectTileModal, {
          node: obj,
          param: appRef.current.tileParm
        }).then(params => {
          const key = appRef.current.tileParm.key;

          // Update list of nodes that are using this parameter
          setParamNodes(nps => {
            nps = cloneDeep(nps);
            if (!nps[key]) {
              nps[key] = [];
            }
            if (params.length === 0) {
              nps[key] = nps[key].filter(p => p.id !== obj.id);
            } else if (!nps[key].find(p => p.id === obj.id)) {
              nps[key].push({
                name: obj.name,
                id: obj.id
              });
            }
            return nps;
          });
          let nodeWasChanged = false;
          // Update node parameter
          Object.keys(obj.parameters).forEach(pkey => {
            if (obj.parameters[pkey] === `##${key}##` && !params.includes(pkey)) {
              obj.parameters[pkey] = null;
              nodeWasChanged = true;
            }
          });
          params.forEach(pkey => {
            if (obj.parameters[pkey] !== `##${key}##`) {
              obj.parameters[pkey] = `##${key}##`;
              nodeWasChanged = true;
            }
          });
          if (nodeWasChanged) {
            obj.updateParameters(obj.parameters);
          }
        }).catch(e => {});
      } else if (Object.values(obj?.parameters || {}).some(val => typeof val === 'string' && val.startsWith('##') && val.endsWith('##'))) {
        alert({
          message: 'This tile currently has parameters set for it and cannot be edited. Remove the parameters first before making other changes.',
          title: 'Tile is Parameterized'
        }).catch(() => {});
      } else if (!!obj && (!obj.componentParams || !!linkEdit)) {
        /**
         * Old Node Modal
         * This is used for nodes that are not using the new `componentParams` and are still using the old `component`.
         * Also this is used for editing node links... Node links should probably be moved to its own component
         */
        setMode(MODES.EDIT_PARAM);
        const {
          left: clientX,
          top: clientY
        } = canvasRef.current.getBoundingClientRect();
        show(NodeModal, {
          readOnly: false,
          node: obj,
          linkEdit: linkEdit,
          stageX: appRef.current.movableContainer.x,
          stageY: appRef.current.movableContainer.y,
          clientX,
          clientY,
          scale: appRef.current.zoom
        }).catch(e => console.error(e)).then(() => {
          setMode(lastMode);
          on('END_PARAM_EDIT', obj, ...args);
        });
      } else if (!!obj && !!obj.componentParams && !linkEdit) {
        /**
         * New Dynamic Node Modal
         * This modal automatically generates the modal based on the configuration.
         * This is the published version
         */
        setMode(MODES.EDIT_PARAM);
        const {
          left: clientX,
          top: clientY
        } = canvasRef.current.getBoundingClientRect();
        show(DynamicModalDraft, {
          node: obj,
          stageX: appRef.current.movableContainer.x,
          stageY: appRef.current.movableContainer.y,
          clientX,
          clientY,
          scale: zoom
        }).catch(e => console.error(e)).then(() => {
          setMode(lastMode);
          on('END_PARAM_EDIT', obj, ...args);
        });
      }
    } else if (event === 'INVALID_NODES') {
      setInvalidNodes([...obj]);
    } else if (event === 'NEW_LINK') {
      // Check for duplicate links
      checkLinks(obj.nodeFrom);
    } else if (event === 'END_PARAM_EDIT') {
      // If obj is link and has an onEvent
      if (obj.onEvent) {
        // Check for duplicates of this on event.
        checkLinks(obj);

        // If the link event is for "clicked", then we need to check that the node has "Create unique urls" selected
        if (obj.onEvent === 'click' && ['action_send_survey_sms', 'action_sms', 'action_mms'].includes(obj.subType) && !obj.parameters?.create_unique_urls) {
          // ALERT ALERT
          confirm({
            title: 'Warning',
            message: 'In order to know if a recipient clicks a link in a text message, you need to first enable the “Create Unique URLs” in this tile. Would you like to do that now?',
            submitText: 'Enable'
          }).then(() => {
            if (!obj.parameters) {
              obj.parameters = {};
            }
            obj.parameters.create_unique_urls = true;
            updateNode(obj.id, {
              parameters: obj.parameters
            });
          }).catch(() => {});
        }
      }
    } else if (event === 'SAVE_NODE') {
      const promises = [];
      const initialNode = {
        type: obj.type,
        sub_type: obj.subType,
        name: obj.name,
        color: '#' + obj.color,
        events: obj.onEventList,
        defaults: obj.parameters,
        params: obj.componentParams
      };
      if (obj.getEventList) {
        promises.push(obj.getEventList({
          node: obj
        }).then(events => {
          initialNode.events = events;
        }));
      }
      if (obj.subType === 'condition_wizard') {
        initialNode.defaults = {
          ...obj.parameters?.values,
          query: obj.parameters?.query,
          target_database: obj.parameters?.target_database
        };
        initialNode.params = (obj.parameters.fields || []).map(field => {
          const param = {
            type: field.type === 'picklist' ? 'select' : 'text',
            label: field.label,
            key: field.variable,
            description: field.description
          };
          if (field.type === 'picklist') {
            param.options = field.options.reduce((res, opt) => {
              res[opt] = opt;
              return res;
            }, {});
          }
          return param;
        });
      }
      Promise.all(promises).then(() => {
        show(SaveNodeModal, {
          node: initialNode
        }).then(data => {
          // Add node to node list
          const node = mapNode(data);
          appRef.current.addNodeToLibrary(node);
          setExtraNodes(en => [...cloneDeep(en), cloneDeep(node)]);
        }).catch(e => {
          console.error(e);
          /* noop */
        });
      });
    }
  };
  const handleDrop = e => {
    if (e.preventDefault) e.preventDefault();
    if (e.stopPropagation) e.stopPropagation();
    if (mode === MODES.CANVAS) {
      const volumeX = volume(appRef.current.movableContainer.scale.x);
      const volumeY = volume(appRef.current.movableContainer.scale.y);
      const sidebarParam = new URLSearchParams(window.location.search).get('sidebar') === 'false';
      const sidebarOffset = sidebarParam ? -65 : localStorage.getItem('sidebarExpanded') === 'true' ? 235 : 30;
      const position = {
        x: (e.clientX - appRef.current.movableContainer.x - sidebarOffset) * volumeX - 145,
        y: (e.clientY - appRef.current.movableContainer.y) * volumeY - 195
      };
      if (snapToGrid) {
        position.x = Math.round(position.x / 25) * 25;
        position.y = Math.round(position.y / 25) * 25;
      }
      const [templateId, subType] = e.dataTransfer.getData('text/plain').split('|');
      appRef.current.addNode(templateId !== '' ? templateId : null, subType, position);
      setNodeInUse(subType);
      let sessionData = localStorage.getItem('sessionDataChangeWarning');
      sessionData = JSON.parse(sessionData);
      handleWarningCheck(sessionData);
      if (sessionData && !sessionData[subType] && sessionDataTiles.includes(subType) || !sessionData && sessionDataTiles.includes(subType)) {
        setSessionTypeChangeWarning(true);
      }
    }
  };
  const publishJourney = () => {
    Promise.resolve().then(() => {
      // Check for time triggers in the past.
      const timeTriggerInPast = appRef.current.nodes.filter(node => {
        if (node.name === 'Time Trigger') {
          const {
            datetime
          } = node.parameters;
          if (DateTime.fromISO(datetime).toUTC().toISO() < DateTime.local().toUTC().toISO()) {
            return true;
          }
        }
        return false;
      });
      if (timeTriggerInPast.length > 0) {
        return confirm({
          title: 'Publish Journey',
          submitText: 'Publish',
          message: <>
                WARNING: You have a Time Trigger that is scheduled for a time that has already passed. The time trigger is scheduled for:
                <br />
                <br />
                {timeTriggerInPast.map((node, index) => <p key={index} style={{
              marginBottom: '0px',
              marginLeft: '8px'
            }}>
                    <b>{moment(node.parameters.datetime).format('MMM D, YYYY h:mm A')}</b>
                  </p>)}
                <br />
                Are you sure you would like to publish this journey?
              </>
        });
      }
    }).then(() => {
      const segmentNodes = appRef.current.nodes.filter(node => {
        if (node.type === 'trigger' && node.parameters?.segment_id) {
          return true;
        }
        return false;
      });
      if (segmentNodes.length > 0) {
        return getSegmentNodes({
          company: asCompany.id,
          segments: segmentNodes.map(node => node.parameters?.segment_id)
        }).then(res => {
          if (res.data.results.length > 0) {
            const duplicateSegments = res.data.results;
            // only show the user the unique duplicate Journeys
            const uniqueJourneys = [];
            duplicateSegments.forEach(segment => {
              if (uniqueJourneys.findIndex(s => s.journey_id === segment.journey_id) === -1) {
                uniqueJourneys.push(segment);
              }
            });
            return show(DuplicateSegmentModal, {
              publishedState: allRevisions.some(r => r.published_when && !r.closed_when && !r.deleted_when),
              open: true,
              duplicateSegments: uniqueJourneys
            }).then(endreason => {
              if (endreason === 'close') {
                throw endreason;
              }
            });
          }
        });
      }
    }).then(() => {
      // Check for already published revisions
      const publishedRevisions = allRevisions.filter(revision => {
        return revision.published_when && !revision.closed_when && !revision.deleted_when;
      });
      if (publishedRevisions.length > 0) {
        return show(RepublishModal).then(endMethod => {
          const vals = {};
          if (endMethod === 'close') {
            vals['closed_when'] = DateTime.local().toUTC().toISO();
          } else if (endMethod === 'stop') {
            vals['deleted_when'] = DateTime.local().toUTC().toISO();
          }

          // We shouldn't have more than one published revision but just in case.
          return publishedRevisions.reduce((promise, r) => promise.then(() => patchRevision(r.id, vals)), Promise.resolve());
        });
      }
    }).then(() => {
      // All done with the above checks so publish the journey
      setIsLoading(true);
      const published_when = DateTime.local().toUTC().toISO();
      return patchRevision(revision.id, {
        published_when
      }).then(() => {
        // Successfully published but we need to reload all the revisions
        return getJourneyRevisions(match.params.id, {
          ordering: '-published_when',
          deleted: 'all',
          limit: 100
        });
      }).then(({
        data
      }) => {
        toasterService.success('Draft successfully published');
        setAllRevisions(data.results);
        setIsLoading(false);
        history.push(`/customer-journeys/published/${match?.params?.id}`);
        setRevision({
          ...revision,
          published_when
        });
      }).catch(err => {
        console.error(err);
        toasterService.error(err.response.data.non_field_errors[0]);
      });
    }).catch(e => {
      // THey most likely canceled one of the above modals
      console.error(e);
      if (e === 'close' || e === 'outside' || e === null) {
        return;
      }
    });
  };
  const revert = e => {
    confirm({
      title: 'Revert Draft Changes',
      submitText: 'Revert',
      message: <>
          This will revert your draft changes back to the published version.
          <br />
          <br />
          Are you sure you want to revert?
        </>
    }).then(() => {
      revertJourney(revision.journey).then(({
        data: revision
      }) => {
        setRevision(revision);
      });
    }).catch(e => {
      console.error(e);
    });
  };
  const testJourney = () => {
    show(TestJourneyModal, {
      revisionID: revision.id
    }).catch(() => {
      console.log('ERROR');
    });
  };
  const toggleSessionTypeChangeWarning = (subType, suppressWarning) => {
    if (subType) {
      setSessionTypeChangeWarning(false);
    }
    const existingData = JSON.parse(localStorage.getItem('sessionDataChangeWarning'));
    let sessionData = existingData ? existingData : {};
    if (subType && sessionDataTiles.includes(subType)) {
      sessionData = {
        ...sessionData,
        [subType]: true
      };
    }
    if (suppressWarning) {
      localStorage.setItem('sessionDataChangeWarning', JSON.stringify(sessionData));
    }
    setSuppressWarning(false);
  };
  const handleWarningCheck = sessionData => {
    if (!sessionData?.trigger_transaction) {
      setIgnoreWarning({
        trigger_transaction: true
      });
    } else if (!sessionData?.review_trigger) {
      setIgnoreWarning({
        review_trigger: true
      });
    } else if (!sessionData?.trigger_abandoned_cart) {
      setIgnoreWarning({
        trigger_abandoned_cart: true
      });
    } else if (!sessionData?.trigger_survey_completed) {
      setIgnoreWarning({
        trigger_survey_completed: true
      });
    } else if (!sessionData?.trigger_time_schedule) {
      setIgnoreWarning({
        trigger_time_schedule: true
      });
    } else if (!sessionData?.trigger_schedule) {
      setIgnoreWarning({
        trigger_schedule: true
      });
    } else {
      setIgnoreWarning({});
    }
  };
  const handleNodeName = node => {
    if (node) {
      if (node.includes('transaction')) {
        return 'Transaction';
      } else if (node.includes('possession') || node.toLowerCase().includes('vehicle')) {
        return 'Vehicle';
      } else if (node.includes('review')) {
        return 'Review';
      } else if (node.includes('cart')) {
        return 'Cart';
      } else if (node.includes('survey')) {
        return 'Survey';
      } else if (node.includes('schedule')) {
        return 'Schedule';
      } else {
        return 'Customer';
      }
    }
  };
  return <>
      <></>
      <Scoped css={css}>
        <Stack direction="row" className="inner-wrapper">
          <div ref={canvasRef} className="journey-canvas" onDragOver={e => e.preventDefault} onDrop={handleDrop}></div>

          <Toolbar extraNodes={extraNodes} nodes={nodes} mode={mode} setMode={setMode} revision={revision} appRef={appRef} trigger={on} paramNodes={paramNodes} />

          <Stack className="journey-top-right" direction="row" spacing="30px">
            {!!invalidNodes.length && <Badge badgeContent={invalidNodes.length} color="warning">
                <IconButton sx={{
              backgroundColor: '#FFDCD2',
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={e => setInvalidNodeEl(!!invalidNodeEl ? null : e.target)}>
                  <WarningIcon />
                </IconButton>
              </Badge>}

            {allRevisions.length > 1 && <IconButton sx={{
            backgroundColor: '#FFF',
            border: '1px solid #D9D9D9',
            ':hover': {
              backgroundColor: '#DAEFFF'
            }
          }} onClick={revert}>
                <UndoIcon />
              </IconButton>}

            <ButtonMui sx={{
            marginLeft: '0px !important',
            height: '32px',
            borderRadius: '27px',
            backgroundColor: '#53A6D6',
            opacity: !!invalidNodes.length ? '50%' : '100%',
            cursor: !!invalidNodes.length ? 'default' : 'cursor',
            ':hover': {
              backgroundColor: !!invalidNodes.length ? '#53A6D6' : '#DAEFFF'
            }
          }} disabled={!!invalidNodes.length} onClick={publishJourney}>
              <PublishIcon fill="#ffffff" />
              <Typography sx={{
              pl: '8px',
              textTransform: 'none',
              color: '#fff'
            }}>
                {allRevisions.length > 1 ? 'Republish' : 'Publish'} Journey
              </Typography>
            </ButtonMui>

            {flags.journeyTest && <ButtonMui sx={{
            marginLeft: '0px !important',
            height: '32px',
            borderRadius: '27px'
          }} onClick={testJourney} variant="contained" color="secondary">
                <BugReportIcon sx={{
              fontSize: '18px'
            }} />
                <Typography sx={{
              pl: '8px',
              textTransform: 'none',
              color: '#fff'
            }}>Test Journey</Typography>
              </ButtonMui>}
          </Stack>

          <div className="journey-bottom-left">
            <Tooltip placement="top" overlay="Select/Move Nodes : a">
              <IconButton sx={{
              backgroundColor: mode === MODES.CANVAS ? '#DAEFFF' : '#FFF'
            }} onClick={() => setMode(MODES.CANVAS)}>
                <MouseIcon />
              </IconButton>
            </Tooltip>

            <Tooltip placement="top" overlay="Move Canvas : s">
              <IconButton sx={{
              backgroundColor: mode === MODES.PAN ? '#DAEFFF' : '#FFF'
            }} onClick={() => setMode(MODES.PAN)}>
                <PanCursorIcon />
              </IconButton>
            </Tooltip>

            <Tooltip placement="top" overlay="Insert Text : d">
              <IconButton sx={{
              backgroundColor: mode === MODES.TEXT ? '#DAEFFF' : '#FFF'
            }} onClick={() => setMode(MODES.TEXT)}>
                <TextFieldsIcon />
              </IconButton>
            </Tooltip>

            {mode === MODES.NODE_PARAM && <Tooltip placement="top" overlay="Select Node">
                <IconButton sx={{
              backgroundColor: mode === MODES.NODE_PARAM ? '#DAEFFF' : '#FFF'
            }} onClick={() => setMode(MODES.NODE_PARAM)}>
                  <LocationSearchingIcon sx={{
                color: '#53A6D6',
                fontSize: 20
              }} />
                </IconButton>
              </Tooltip>}
          </div>

          <div className="journey-bottom-right">
            <Paper>
              <Stack direction="row" spacing="30px">
                <Stack sx={{
                flexWrap: 'wrap',
                display: 'flex',
                alignItems: 'center'
              }} direction="row" spacing="10px">
                  <Checkbox checked={showGrid} value={showGrid} onChange={() => setShowGrid(!showGrid)} className="show-grid" />

                  <Typography>Show Grid</Typography>

                  <Checkbox checked={snapToGrid} value={snapToGrid} onChange={() => setSnapToGrid(!snapToGrid)} className="snap-to-grid" />

                  <Typography>Snap to Grid</Typography>
                </Stack>
                <Stack sx={{
                flexWrap: 'wrap',
                display: 'flex',
                alignItems: 'center'
              }} direction="row" spacing="10px">
                  <IconButton onClick={() => setZoom(zoom > 0.6 ? Math.round((zoom - 0.1) * 10) / 10 : 0.5)} disabled={zoom === 0.5}>
                    <RemoveIcon />
                  </IconButton>
                  <Typography> {zoom * 100}%</Typography>
                  <IconButton onClick={() => setZoom(zoom < 1 ? Math.round((zoom + 0.1) * 10) / 10 : 1)} disabled={zoom === 1}>
                    <AddIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </Paper>
          </div>
        </Stack>

        <Menu anchorEl={invalidNodeEl} open={!!invalidNodeEl} onClose={() => setInvalidNodeEl(null)} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }} transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}>
          <Typography sx={{
          pl: 2
        }} variant="tableHeader">
            {invalidNodes?.length > 1 ? 'Invalid Tiles' : 'Invalid Tile'}
          </Typography>

          {invalidNodes.map((node, index) => <MenuItem key={node.id + index} onClick={() => appRef.current.scrollToNode(node.id)}>
              {node.name} <ArrowCircleRightIcon />
            </MenuItem>)}
        </Menu>

        <Dialog sx={{
        '& .MuiDialog-paper': {
          maxWidth: '600px',
          width: '100%',
          borderRadius: '24px',
          padding: '12px'
        }
      }} open={sessionTypeChangeWarning} onClose={() => {
        setSessionTypeChangeWarning(false);
        setSuppressWarning(false);
      }} id="session-type-change-warning">
          <DialogTitle>
            <Stack direction="row" spacing={1}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                {nodeInUse && nodeInUse.includes('transaction') ? <TransactionIcon /> : nodeInUse && nodeInUse.includes('possession') ? <VehicleIcon /> : nodeInUse && nodeInUse.includes('review') ? <ReviewIcon /> : nodeInUse && nodeInUse.includes('cart') ? <ShoppingCartIcon /> : nodeInUse && nodeInUse.includes('survey') ? <SurveysIcon /> : nodeInUse && nodeInUse.includes('schedule') ? <Calendar30DaysIcon /> : <CustomerIcon />}
              </Box>
              <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                <Typography>{` ${nodeInUse && handleNodeName(nodeInUse)} Journey Session`}</Typography>
              </Box>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
              <Typography>
                {`
                  ${handleNodeName(nodeInUse)} data is now available for customers who enter the journey through this trigger. You can filter on this specific customer ${handleNodeName(nodeInUse)} data in your Check Data Field tiles or use this ${handleNodeName(nodeInUse)} information in your communications (email, text messages, or mail).
                    `}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center',
              pr: '192px'
            }}>
                <Checkbox checked={suppressWarning} onChange={() => setSuppressWarning(prev => !prev)} sx={{
                color: '#53A6D6'
              }} />{' '}
                <Typography sx={{
                cursor: 'default'
              }}>{`Don't show this warning again`}</Typography>
              </Box>
              <Button variant="contained" sx={{
              width: '100px'
            }} onClick={() => {
              toggleSessionTypeChangeWarning(nodeInUse, suppressWarning);
            }} autoFocus>
                Okay
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </Scoped>
    </>;
};
const css = {
  styles: `[kremling="i22"] body,body[kremling="i22"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i22"] .inner-wrapper,[kremling="i22"].inner-wrapper {
  width: 100%;
  height: 100%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  position: relative;
}

[kremling="i22"] .journey-canvas,[kremling="i22"].journey-canvas {
  flex-grow: 1;
  overflow: hidden;
}

[kremling="i22"] .revision-picker,[kremling="i22"].revision-picker {
  position: absolute;
  top: 1rem;
}

[kremling="i22"] .journey-bottom-right,[kremling="i22"].journey-bottom-right {
  display: flex;
  align-items: center;
  padding: 0 1rem 1rem 0;
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 315px;
}
[kremling="i22"] .journey-bottom-right .show-grid,[kremling="i22"] .journey-bottom-right .snap-to-grid,[kremling="i22"].journey-bottom-right .show-grid,[kremling="i22"].journey-bottom-right .snap-to-grid {
  color: #1d252d;
}
[kremling="i22"] .journey-bottom-right .show-grid.Mui-checked,[kremling="i22"] .journey-bottom-right .snap-to-grid.Mui-checked,[kremling="i22"].journey-bottom-right .show-grid.Mui-checked,[kremling="i22"].journey-bottom-right .snap-to-grid.Mui-checked {
  color: #53a6d6;
}
[kremling="i22"] .journey-bottom-right .show-grid .MuiSvgIcon-root,[kremling="i22"] .journey-bottom-right .snap-to-grid .MuiSvgIcon-root,[kremling="i22"].journey-bottom-right .show-grid .MuiSvgIcon-root,[kremling="i22"].journey-bottom-right .snap-to-grid .MuiSvgIcon-root {
  font-size: 22px;
}
[kremling="i22"] .journey-bottom-right div.control,[kremling="i22"].journey-bottom-right div.control {
  width: 2.2rem;
  height: 2.2rem;
}
[kremling="i22"] .journey-bottom-right div.count,[kremling="i22"].journey-bottom-right div.count {
  font-size: 1.2rem;
  color: #606060;
  font-weight: 700;
  height: 2.2rem;
  line-height: 2.2rem;
  padding: 0 0.5rem;
  margin: 0 0.5rem;
  width: 4rem;
  background-color: #fff;
  border-radius: 0.4rem;
  text-align: center;
}

[kremling="i22"] .journey-bottom-left,[kremling="i22"].journey-bottom-left {
  position: absolute;
  padding: 0 1rem 1rem 1rem;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  user-select: none;
}
[kremling="i22"] .journey-bottom-left>button,[kremling="i22"].journey-bottom-left>button {
  margin-right: 1rem;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

[kremling="i22"] .journey-top-right,[kremling="i22"].journey-top-right {
  position: absolute;
  top: 0;
  right: 315px;
  padding: 1rem;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
}
[kremling="i22"] .journey-top-right button,[kremling="i22"].journey-top-right button {
  margin: 0;
}

[kremling="i22"] .journey-top-right__toggle,[kremling="i22"].journey-top-right__toggle {
  margin-right: 1rem;
  position: relative;
}
[kremling="i22"] .journey-top-right__toggle>button,[kremling="i22"].journey-top-right__toggle>button {
  margin-right: -0.1rem;
  border-radius: 0;
  z-index: 0;
}
[kremling="i22"] .journey-top-right__toggle>button:first-child,[kremling="i22"].journey-top-right__toggle>button:first-child {
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
}
[kremling="i22"] .journey-top-right__toggle>button:last-child,[kremling="i22"].journey-top-right__toggle>button:last-child {
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}`,
  id: 'i22',
  namespace: 'kremling'
};